/*-----------------------------------------------------------------------------*/
/*------------------------------INTRO-------------------------------------------*/
/*-----------------------------------------------------------------------------*/
section#intro {
  height: 100vh;
  position: relative;
  width: 100%;
}

.videoIntro {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
  height: 100%;
  object-fit: cover;
  width: 100%;
}

#intro {
  display: flex;
  align-items: center;
  justify-content: center;
}
.bannerText {
  color: white;
  text-align: center;

  z-index: 1;
  letter-spacing: 2px;
}
@keyframes intro {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }
}

.bannerText h1 {
  padding-bottom: 20px;
  font-size: 3.6rem;
  animation: intro 400ms;
}

.bannerText h2 {
  padding: 15px;
  font-size: 28px;
  font-weight: 500;
  animation: intro 650ms;
}

.bannerText p {
  color: #1877f2;
  font-size: 20px;
  animation: intro 1000ms;
}
.bannerText a,
.aboutGridIzq a.blue {
  position: relative;
}
.bannerText a:after,
.aboutGridIzq a.blue::after {
  content: "";
  position: absolute;

  bottom: -10px;

  background: #1877f2;

  transform: translateX(-50%);
  width: 45%;
  left: -25%;
  height: 0px;
  transition: all 0.5s ease;
}
.bannerText a:hover:after,
.aboutGridIzq a.blue:hover::after {
  left: 0;
  left: 50%;
  height: 1px;
}

@media (max-width: 405px) {
  .bannerText h1 {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .bannerText {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
